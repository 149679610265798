import React from "react"

export default function CheapUltramarinesArmy() {
  return (
    <div>
      <header>
        <h1 className="site-title">
          <a href="/">The Dangron Hob-blog</a>
        </h1>
        <small>
          In the Grim Darkness of the 21st century there isn't really anything
          to do.
        </small>
      </header>
      <main>
        <h1>Cheap Ultramarines Army</h1>

        <p>
          I tried out a Primaris Space Marine Captain in Phobos Armour in a game
          the other night, the 12 inch aura which stops enemies deep striking
          nearby and ability to deploy anywhere on the board was really strong.
          I wondered about how I could build a cheap army around this and didn't
          need to look any further than the Start Collecting Vanguard Space
          Marines box.
        </p>

        <p>
          The list I have made from the box is thematically off, I cannot see
          Roboute Guilliman engaging in stealth tactics or taking command of a
          few Eliminators but in the name of cheaphammer, it will be so.
        </p>

        {/* prettier-ignore */}
        <pre>{`
          ++ Super-Heavy Auxiliary Detachment ++
          ++ (Imperium - Adeptus Astartes - Ultramarines) ++ 
          
          Roboute Guilliman [19 PL, 3CP, 380pts]: Warlord
          
          ++ Battalion Detachment ++
          
          ++ (Imperium - Adeptus Astartes - Ultramarines) ++
          
          + HQ + 
          Lieutenant in Phobos Armour [4 PL, 80pts]
          .Occulus Bolt Carbine and Bolt Pistol: Grav-chute

          Lieutenant in Phobos Armour [4 PL, 80pts]
          .Occulus Bolt Carbine and Bolt Pistol: Grav-chute

          Lieutenant in Phobos Armour [4 PL, 80pts]
          .Occulus Bolt Carbine and Bolt Pistol: Grav-chute
          
          + Troops +
          Infiltrator Squad [12 PL, 250pts]:
          . Infiltrator Helix Adept, Infiltrator Sergeant
          . 8x Infiltrator 
          
          Infiltrator Squad [12 PL,250pts]:
          . Infiltrator Helix Adept, Infiltrator Sergeant
          . 8x Infiltrator
          
          Infiltrator Squad [12 PL, 250pts]:
          . Infiltrator Helix Adept, Infiltrator Sergeant
          . 8x Infiltrator
          
          + Fast Attack +
          Suppressor Squad [5 PL, 105pts]

          Suppressor Squad [5 PL, 105pts]

          Suppressor Squad [5 PL, 105pts]
          
          + Heavy Support +
          Eliminator Squad [5PL, 90pts]
          . Eliminator Sergeant: Bolt sniper rifle, Camo cloak
          . Eliminator with Bolt Sniper: Camo cloak
          . Eliminator with Bolt Sniper: Camo cloak
          
          Eliminator Squad [5 PL, 90pts]
          . Eliminator Sergeant: Boltsniper rifle, Camo cloak
          . Eliminator with Bolt Sniper: Camo cloak
          . Eliminator with Bolt Sniper: Camo cloak
          
          Eliminator Squad [5 PL, 90pts]
          . Eliminator Sergeant: Bolt sniper rifle, Camo cloak
          . Eliminator with Bolt Sniper: Camo cloak
          . Eliminator with Bolt Sniper: Camo cloak
          
          ++ Total: [97 PL, 12CP, 1,955pts] ++
        `}</pre>

        <p>
          This force utilises the magic triangle for cheap armies:
          <ul>
            <li>Elite army to ensure high points to pounds ratio</li>
            <li>Stacking good value Start Collecting Boxes</li>
            <li>Point dense beatstick characters</li>
          </ul>
        </p>

        <p>
          This army is a bit odd, the only anti tank is the 9 surpressor
          autocannons which at S7 won't do a lot and the 3 Lieutenants is very
          weird, a Phobos Captain and Librarian would certainly be more optimal
          but hey, we are on a budget here.
        </p>

        <p>
          Some tinkering would probably allow for a couple of{" "}
          <a
            target="_blank"
            href="https://www.goblingaming.co.uk/products/primaris-space-marine-invictor-tactical-warsuit?tap_a=18936-14583c&tap_s=268932-ebecc6"
          >
            Invictor Warsuits
          </a>{" "}
          in a 2000 points list and I think this would be really strong,
          fantastic board control and objective grabbing abilities and its Space
          Marines who have access to pretty much every tool in the box.
        </p>

        <p>
          This army is great value, I've provided links to pick up the kits
          here:
          <ul>
            <li>
              <a
                target="_blank"
                href="https://www.goblingaming.co.uk/products/start-collecting-vanguard-space-marines?tap_a=18936-14583c&tap_s=268932-ebecc6"
              >
                View Start Collecting Vanguard Space Marines on Goblin Gaming
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.goblingaming.co.uk/products/roboute-guilliman-ultramarines-primarch?tap_a=18936-14583c&tap_s=268932-ebecc6"
              >
                View Roboute Guilliman on Goblin Gaming
              </a>
            </li>
          </ul>
        </p>
      </main>
    </div>
  )
}
